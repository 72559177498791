@import "../../ui/media.scss";

a,
.link,
.as-link {
  color: var(--color-link);
  font-weight: 500;
  text-decoration: none;
  vertical-align: baseline;

  &.block {
    display: block;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--color-link-hovered);
    text-decoration: underline;
  }

  .icon {
    width: 20px;
    fill: var(--color-link);
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }

  &.postfix {
    .icon {
      margin: -2px 0 0 4px;
    }
  }

  .icon-external {
    fill: none;
    stroke: var(--color-link);
    width: 16px;
    stroke-width: 2px;

    @include tablet {
      position: relative;
      top: 2px;
    }
  }
}
