@use "sass:math";

.modal {
  // Modal transition
  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity 150ms 100ms ease-in-out;
  }

  .ReactModal__Content {
    transform: scale(1.05);
    transition: transform 250ms ease-in-out;
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    transform: scale(0.95);
  }

  $modal-spacing: 20px;
  $modal-header-height: 160px;

  // Modal default styling
  &.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: var(--z-dialog);
    justify-content: center;
    align-items: center;
  }

  .ReactModal__Content {
    padding: 0;
    background-color: #fcfcfc;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
    max-height: 100%;
    margin: 10px;
    overflow-x: hidden;
    padding-bottom: 10px;
    outline: none;
  }

  .modal-body {
    padding: $modal-spacing;
    overflow: auto;
    max-height: calc(100vh - #{$modal-header-height - $modal-spacing});
    overflow-x: hidden;
  }
}
