@import "../../ui/media.scss";

.inputs {
  .address-popover {
    display: none;
  }

  .nav-button {
    min-width: 140px;
  }

  .owner-title {
    .input-label-wrapper {
      display: none;
    }
  }

  hr {
    @include tablet {
      display: none;
    }
  }

  svg {
    display: block;
    overflow: visible;
  }
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}
