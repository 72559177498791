@import "../../ui/media.scss";

.hidden-input {
  .input-messages {
    line-height: 1.3;
    position: relative;
    font-size: 1.5rem;
    font-style: italic;
    color: var(--color-text-passive);
    min-height: 20px;
    padding: 4px 0 0 10px;

    @include tablet {
      font-size: 1.7rem;
      min-height: 22px;
    }
  }

  &.has-error {
    .input-messages,
    .input-label {
      color: var(--color-text-error);
    }
  }
}
