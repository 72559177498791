.document-upload-phone {
  h1.absolute-header {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: bold;
    color: var(--color-white);
  }

  video {
    object-fit: cover;
    height: 100vh;
  }

  .video-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
  }

  .video-document-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .video-document-overlay {
    box-shadow: 0px 0px 0px 5000px rgba(255, 255, 255, 0.3);
    border: 8px solid rgba(255, 255, 255, 0.7);
    overflow: hidden;
    position: absolute;
    top: 30%;
    left: 8%;
    height: 35%;
    width: 84%;
    border-radius: 25px;
  }

  .video-canvas {
    display: none;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: hsla(0, 0%, 0%, 0.8);
    padding: 16px 0;

    .button {
      height: 56px;
    }

    .empty-slot {
      width: 44px;
    }
  }
}

.document-phone-confirm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  height: 100vh;

  img {
    object-fit: cover;
    height: 100%;
  }

  h1.absolute-header {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: bold;
    color: var(--color-white);
  }

  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: hsla(0, 0%, 0%, 0.8);
    padding: 24px 0;

    .empty-slot {
      width: 38px;
    }
  }
}
