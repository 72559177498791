@import "../../ui/media.scss";

.address-popover {
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    // background-color: rgba(255, 255, 255, 0.5);
    z-index: var(--z-dialog-backdrop);
  }

  &__container {
    position: fixed;
    bottom: -50px;
    left: 0;
    right: 0;
    z-index: var(--z-dialog);
    background-color: var(--color-white);
    box-shadow: var(--color-button-shadow);
    padding: 12px;
    padding-bottom: calc(50px + 12px);

    @include tablet {
      position: absolute;
      top: 14px;
      left: -12px;
      right: -12px;
      bottom: unset;
      padding-bottom: 12px;
    }

    .button {
      border-radius: 100px;
    }
  }

  .address-form {
    padding: 12px 12px 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 12px;

    .button {
      min-width: 160px;
    }
  }
}
