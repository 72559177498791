.translation-editor-overlay {
  .translation-columns {
    display: flex;
    gap: 40px;

    > div:first-child {
      flex: 3;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
    }

    .translation-editor-overlay-alternatives {
      flex: 2;
    }
  }

  p {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--color-progress-border);
    border-radius: 5px;
    background-color: var(--color-smoke);
  }

  .button-row {
    display: flex;
    margin-top: auto;

    > * {
      flex: 1;
    }
  }
}
