.status-box {
  position: relative;

  .status-box-message {
    padding: 7px 0 0 58px;
    line-height: 1.5;
  }

  &.default-box {
    .status-box-message {
      padding: 7px 0 0 0;
    }
  }

  .status-box-icon {
    height: 42px;
    width: 42px;
    border-radius: 21px;
    padding: 6px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .icon {
    width: 30px;
    height: 30px;
    display: none;
  }

  &.error-box {
    .status-box-icon {
      background-color: rgba(246, 234, 236, 1);
      box-shadow: 0 0 14px -6px #882323;
    }

    .icon-error-outline {
      fill: #c04647;
      display: block;
    }
  }

  &.success-box {
    .status-box-icon {
      background-color: #e5f2f4;
      box-shadow: 0 0 14px -6px #27624e;
    }

    .icon-checkmark {
      fill: var(--color-link);
      display: block;
    }
  }

  &.warning-box {
    .status-box-icon {
      background-color: rgba(255, 246, 213, 1);
      box-shadow: 0 0 14px -6px #74510b;
    }

    .icon-checkmark {
      fill: #e69900;
      display: block;
    }
  }
}
