@import "../../ui/media.scss";

.done-page {
  .all-done-box {
    max-width: 600px;
    margin: 0 auto 40px auto;
    padding: 16px 16px 20px 8px;
    background-color: #e5f2f4;
    color: var(--color-link);
    border-radius: 10px;
    display: flex;
    // align-items: center;
    line-height: 1.5;

    @include landscape {
      padding: 16px 16px 20px 12px;
      margin: 50px auto 60px auto;
    }

    .beacon {
      margin: 0 8px 0 0;
      flex: 0 0 auto;
      align-self: flex-start;
      position: relative;
      top: 2px;

      @include tablet {
        margin: 0 10px 0 0;
      }
    }

    strong {
      display: block;
      font-size: 2.8rem;
    }
  }
}
