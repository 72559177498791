@import "../../../ui/media.scss";

a.as-button,
.button {
  font-weight: 600;
  height: var(--height-interaction);
  padding: 0 16px;
  transition: background-color 300ms ease, box-shadow 300ms ease;
  background-color: var(--color-button);
  box-shadow: var(--color-button-shadow);
  color: var(--color-white);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;

  &-content {
    display: flex;
  }

  &.align-input-label {
    margin: 28px 0 0 0;
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  @include tablet {
    height: var(--height-interaction-tablet);
  }

  &.action {
    height: var(--height-interaction);
    width: var(--height-interaction);
    border-radius: 50%;
    padding: 0;

    @include tablet {
      height: var(--height-interaction-tablet);
      width: var(--height-interaction-tablet);
    }

    .icon {
      margin: 0;
    }
  }

  &.mini {
    height: var(--height-interaction-mini);

    @include tablet {
      height: var(--height-interaction-mini-tablet);
    }

    &.action {
      width: var(--height-interaction-mini);

      .icon {
        width: 12px;
        height: 12px;

        @include tablet {
          width: 16px;
          height: 16px;
        }
      }

      @include tablet {
        width: var(--height-interaction-mini-tablet);
      }
    }
  }

  &.small {
    height: var(--height-interaction-small);

    @include tablet {
      height: var(--height-interaction-small-tablet);
    }

    &.action {
      width: var(--height-interaction-small);

      .icon {
        width: 14px;
        height: 14px;

        @include tablet {
          width: 18px;
          height: 18px;
        }
      }

      @include tablet {
        width: var(--height-interaction-small-tablet);
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-button-hovered);
    box-shadow: var(--color-button-shadow-transparent);
    color: var(--color-white);
    text-decoration: none;
  }

  & + .button {
    margin: 0 0 0 10px;
  }

  & + .button.block,
  & + .as-button.block {
    margin: 10px 0 0 0;
  }

  &.block {
    display: flex;
    width: 100%;

    & + .button {
      margin: 10px 0 0 0;
    }
  }

  .icon {
    margin: 0 0 0 6px;
  }

  span > .icon {
    display: block;
  }

  &.danger-button {
    background-color: var(--color-red-heavy);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color-red);
    }
  }

  &.success-button {
    background-color: var(--color-green);
  }

  &.has-error,
  &.was-successful,
  &.is-pending,
  &.is-disabled {
    color: var(--color-white);
    box-shadow: none;
  }

  &.has-error {
    background-color: var(--color-red-heavy);
  }

  &.was-successful {
    background-color: var(--color-green);
  }

  &.is-pending {
    background-color: var(--color-silver);
    cursor: wait;
  }

  &.is-disabled {
    background-color: var(--color-silver);
    cursor: not-allowed;
  }

  &.ghost {
    background-color: var(--color-white);
    color: var(--color-button-ghost);
    border: 2px solid var(--color-button-ghost);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--link-alt);
    }

    &.danger-button {
      background-color: var(--color-white);
      color: var(--color-red-heavy);
      border: 2px solid var(--color-red-heavy);

      &:hover,
      &:focus,
      &:active {
        background-color: var(--color-delete-bg);
      }

      .icon {
        fill: var(--color-red-heavy);
      }
    }

    &.has-error {
      color: var(--color-red-heavy);
      border: 2px solid var(--color-red-heavy);

      .icon {
        fill: var(--color-red-heavy);
      }
    }

    &.was-successful {
      color: var(--color-green);
      border: 2px solid var(--color-green);

      .icon {
        fill: var(--color-green);
      }
    }

    &.is-pending,
    &.is-disabled {
      color: var(--color-silver);
      border: 2px solid var(--color-silver);

      .icon {
        fill: var(--color-silver);
        & > span {
          background-color: var(--color-silver);
        }
      }
    }
  }

  &.ghost {
    .close {
      line {
        stroke: var(--color-link);
      }
    }
  }

  &.as-text {
    background-color: transparent;
    color: var(--color-link);
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: var(--color-link-hovered);
      text-decoration: underline;
    }

    &.danger-button {
      color: var(--color-red-heavy);

      &:hover,
      &:active,
      &:focus {
        color: var(--color-red);
      }
    }

    &.has-error {
      color: var(--color-red-heavy);

      .icon {
        fill: var(--color-red-heavy);
      }
    }

    &.was-successful {
      color: var(--color-green);

      .icon {
        fill: var(--color-green);
      }
    }

    &.is-pending,
    &.is-disabled {
      color: var(--color-silver);
    }
  }
}
