.translation-list {
  background-color: var(--color-white);
  border: 2px solid var(--color-link);
  border-radius: 6px;
  box-shadow: var(--color-button-shadow);
  padding: 20px 16px 20px 16px;
  width: 300px;

  ul {
    border: 1px solid var(--color-input-border);
    overflow: hidden;
    border-radius: 4px;
    margin: 0;
    max-height: 300px;
    overflow: auto;

    li:last-child {
      border: 0;
    }

    li {
      border-bottom: 1px solid var(--color-input-border);
      padding: 4px 10px;
      display: block;
      transition: background-color 0.15s ease-in;
      font-size: 16px;
      cursor: pointer;
      line-height: 1.5;
      width: 100%;
      display: flex;
      align-items: center;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: var(--link-alt);
      }

      .missing-icon,
      .translated-icon {
        flex-shrink: 0;
      }

      .missing-icon {
        background-color: rgba(246, 234, 236, 1);
        fill: #c04647;
        margin-right: 6px;
        border-radius: 20px;
      }

      .translated-icon {
        background-color: rgba(196, 247, 230, 1);
        fill: #13ae7a;
        margin-right: 6px;
        border-radius: 20px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
