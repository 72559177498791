.wl-form {
  &.wl-form-pending {
    position: relative;

    .form-pending-spinner {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
