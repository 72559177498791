.translation-management-overlay-alternatives {
  min-width: 0;

  .conditional-translation-list {
    border: 1px solid var(--color-input-border);
    border-radius: 4px;
    overflow: hidden;
  }

  .translation-row {
    border-bottom: 1px solid var(--color-input-border);

    &:last-child {
      border: 0;
    }

    div {
      padding: 4px 10px;
      display: block;
      transition: background-color 0.15s ease-in;
      font-size: 16px;
      cursor: pointer;
      line-height: 1.5;
      width: 100%;

      &:hover {
        background-color: var(--link-alt);
      }

      &.selected {
        background-color: var(--link-alt);
        font-weight: 500;
      }

      span,
      i {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      }

      i {
        font-size: 14px;
        color: var(--color-link);
        margin-top: 2px;
      }

      .not-found {
        color: var(--color-text-error);
      }
    }
  }
}
