.page {
  padding: 0 0 100px 0;
  min-height: 100vh;

  .logo-wrapper {
    margin: 0 0 40px 0;
    color: white;
  }

  & > section {
    padding-top: 60px;
  }
}

.text-box {
  max-width: 580px;
  margin: 0 auto;
}
