@import "../../ui/media.scss";

.circle-bg {
  height: 200px;
  padding: 80px 0;
  background-image: linear-gradient(-225deg, #b6dcdc 60%, #419494);
  background-image: linear-gradient(-250deg, #273444 30%, #19424e 50%, #3c4858);
  overflow: hidden;
  position: relative;

  .circle-bg-front {
    position: relative;
    z-index: 2;
  }

  .circle-bg-b {
    position: absolute;
    z-index: 1;

    width: 30vw;
    height: 30vw;
    position: absolute;
    z-index: 1;
    right: -20vw;
    top: -20px;
    right: -15vw;
    top: -40px;
    // opacity: 0.4;

    @include tablet {
      right: -10vw;
      top: -80px;
    }

    @include desktop {
      width: 20vw;
      height: 20vw;
      right: -10vw;
      top: -100px;
    }

    svg {
      transform: rotate(-60deg);
    }
  }

  .circle-bg-a,
  .circle-bg-b {
    animation-duration: 100s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-delay: 0ms;
    animation-name: turn-b;
  }

  .circle-bg-a {
    animation-name: turn-a;
  }

  .circle-bg-a {
    width: 80vw;
    height: 80vw;
    position: absolute;
    z-index: 1;
    right: -40vw;
    top: -120px;
    // opacity: 0.6;

    @include landscape {
      width: 80vw;
      height: 80vw;
      right: -40vw;
      top: -180px;
    }

    @include tablet {
      width: 60vw;
      height: 60vw;
      right: -20vw;
      top: -180px;
    }

    @include desktop {
      width: 30vw;
      height: 30vw;
      right: -5vw;
      top: -100px;
    }

    svg {
      transform: rotate(-100deg);
    }
  }

  svg {
    fill: none;
    overflow: hidden;
  }

  .left-side {
    stroke: #027dba;
    stroke-width: 8px;
  }

  .right-side {
    stroke: #5ac8b7;
    stroke-width: 8px;
  }

  & > .image {
    background-color: transparent;
  }
}

@keyframes turn-a {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes turn-b {
  0% {
    transform: rotate(0deg);
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0.3;
  }
}
