@import "../../ui/media.scss";

.language-toggle {
  @include spadding;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin: 0 0 0 10px;
    overflow: hidden;
    display: inline-flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 10px -3px var(--color-slate);
    transition: all 300ms ease;

    & > span {
      height: 30px;
      border-radius: 50%;
      width: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      box-shadow: 0 0 10px -3px transparent;
      background-color: #d4dde9;
    }
  }
}
