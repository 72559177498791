@import "../../ui/media.scss";

$image-width: 50px;

.id-page {
  .image {
    background-color: transparent;
  }

  .id-fallback {
    margin: 60px 0 0 0;
  }

  .provider-divider {
    margin-top: 20px;

    @include tablet {
      display: none;
    }
  }

  .id-provider {
    display: flex;
    line-height: 1.5;
    flex-wrap: wrap;
    align-items: center;

    .id-logo-text {
      flex: 0 0 calc(100% - $image-width);
      padding: 0 0 0 22px;
    }

    .id-logo-wrapper {
      flex: 0 0 $image-width;
      padding: 0;
    }
  }

  .tablet-columns {
    & > div {
      .id-logo-button {
        flex: 1 0 auto;
        margin: 20px 0 0 0;
      }
    }
  }

  .id-logo-wrapper {
    width: $image-width;
  }
}
