@import "./media.scss";

.app {
  p {
    font-size: 1.4rem;
    line-height: 1.7;
    margin: 14px 0;

    @include tablet {
      font-size: 1.6rem;
    }
  }

  .text-mini {
    font-size: 1rem;

    @include tablet {
      font-size: 1.2rem;
    }
  }

  .text-small {
    font-size: 1.2rem;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  .text-regular-plus {
    font-size: 1.6rem;

    @include tablet {
      font-size: 1.8rem;
    }
  }

  .text-large {
    font-size: 1.8rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  .text-huge {
    font-size: 2rem;

    @include tablet {
      font-size: 2.3rem;
    }
  }

  .text-secondary {
    color: var(--color-text-passive);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    & + p {
      margin: 0 0 14px 0;
    }
  }

  h1 {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 300;
    margin: 0 0 14px 0;
    letter-spacing: -0.05rem;

    @include tablet {
      font-size: 3.6rem;
    }
  }

  h2 {
    font-size: 2.8rem;
    line-height: 1.3;
    font-weight: 300;
    margin: 0 0 14px 0;
    letter-spacing: -0.05rem;

    @include tablet {
      font-size: 3.3rem;
    }
  }

  h3 {
    font-size: 2.3rem;
    line-height: 1.5;
    font-weight: 900;
    margin: 0 0 10px 0;

    @include tablet {
      font-size: 2.8rem;
    }
  }

  h4 {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 900;
    margin: 0 0 10px 0;

    @include tablet {
      font-size: 2.2rem;
    }
  }

  h5 {
    font-size: 1.6rem;
    line-height: 1.7;
    font-weight: 900;
    margin: 0 0 10px 0;
  }

  h6 {
    font-size: 1.4rem;
    line-height: 1.7;
    font-weight: 900;
    margin: 0 0 10px 0;
  }

  b {
    font-weight: 700;
  }

  strong {
    font-weight: 900;
  }

  // blockquote,
  // i {
  //   font-style: normal;
  // }

  .truncated {
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  figure,
  blockquote {
    margin: 0;
    line-height: 1.5;
  }

  figcaption {
    cite {
      font-style: normal;
      font-size: 1.4rem;
      color: var(--color-sub-text);

      @include tablet {
        font-size: 1.6rem;
      }
    }
  }

  sup {
    vertical-align: top;
  }

  .text-right {
    text-align: right;
  }

  .small {
    font-size: 1.4rem;
    line-height: 1.4;

    @include tablet {
      font-size: 1.6rem;
    }
  }
}
