@import "./ui/media.scss";

:root {
  --z-vertical-nav: 100;
  --z-backdrop: 60;
  --z-dialog: 200;
  --z-dialog-backdrop: 150;

  --link: #4974d0;
  --link-hovered: #459fff;
  --link-shadow: #326aa7;
  --link-alt: #e5f2f4;

  --color-green: #2eb56a;
  --color-green-light: #29eb7f;
  --color-green-heavy: #0f9f4f;

  --color-slate: #3c4858;
  --color-pure-black: #000;
  --color-white: #fff;
  --color-black: #1f2d3d;
  --link: #007b7c;
  --link-hovered: #46beaa;
  --link-shadow: #284646;
  --color-white: #fff;
  --color-pending: #8492a6;
  --color-disabled: #8492a6;
  --color-silver: #8492a6;
  --color-fieldset-border: #c0ccda;
  --color-bg: #f5f6fa;
  --color-delete-light: #ffeff2;
  --color-delete-heavy: #fc607d;
  --color-done-light: #c9f2be;
  --color-done-heavy: #397129;
  --color-ghost: #646f80;
  --color-card-details: var(--link-hovered);

  --color-pure-black: #000;
  --color-black: #1f2d3d;
  --color-steel: #273444;
  --color-slate: #3c4858;
  --color-ghost: #646f80;
  --color-silver: #8492a6;
  --color-white: #fff;

  --color-smoke: #d3dce6;
  --color-smoke-light: #e0e6ed;
  --color-smoke-heavy: #c0ccda;

  --color-snow: #f5f6fa;
  --color-snow-light: #f9fafc;
  --color-snow-heavy: #e5e9f2;

  --color-red: #df7676;
  --color-red-light: #f6eaec;
  --color-red-heavy: #c04647;

  --color-text: var(--color-black);
  --color-text-passive: var(--color-ghost);
  --color-text-error: var(--color-red-heavy);
  --color-text-success: var(--color-green-heavy);
  --color-button-ghost: var(--link);
  --color-progress-bar-bg: var(--color-snow);
  --color-progress-border: var(--color-smoke-heavy);
  --color-link: var(--link);
  --color-link-hover: var(--link-hovered);

  --color-button: var(--link);
  --color-button-hovered: var(--link-hovered);

  --height-interaction-mini: 24px;
  --height-interaction-mini-tablet: 28px;
  --height-interaction-small: 30px;
  --height-interaction-small-tablet: 34px;
  --height-interaction: 38px;
  --height-interaction-tablet: 42px;

  --color-card-bg: var(--color-white);
  --color-input-border: var(--color-silver);
  --color-input-bg: var(--color-white);

  --color-delete-bg: var(--color-delete-light);
  --color-delete-text: var(--color-delete-heavy);

  --color-button-shadow: 0 4px 16px -7px var(--link-shadow);
}

html {
  font-size: 62.5%;
  background-color: var(--color-bg);
}

body {
  font-size: 1.4rem;
  line-height: 1.7;
  font-weight: 400;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);

  @include tablet {
    font-size: 1.6rem;
  }
}

.CZ {
  font-family: "Open Sans", sans-serif;

  button {
    font-family: "Open Sans", sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  @include spadding;
  padding-bottom: 40px;
}

article {
  max-width: $desktop;
  margin: 0 auto;
}

.center {
  text-align: center;
}

button {
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  font-size: 1.6rem;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  color: var(--color-button);

  @include tablet {
    font-size: 1.8rem;
  }
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tablet-flex {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}

.columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);

    @include tablet {
      flex: 0 0 calc(50% - 25px);
      max-width: calc(50% - 25px);
    }
  }

  & > .full {
    flex: 0 0 100%;
    max-width: 100%;

    @include tablet {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.tablet-columns {
  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & > div {
    margin: 20px 0 0 0;

    &:nth-child(1) {
      margin-top: 0;
    }

    @include tablet {
      flex: 0 0 calc(50% - 20px);

      &:nth-child(-n + 2) {
        margin-top: 0;
      }
    }
  }
}

// .success-box,
// .error-box {
//   background-color: var(--color-delete-light);
//   color: var(--color-delete-heavy);
//   font-weight: 500;
//   border-radius: 8px;
//   padding: 16px;
// }

// .success-box {
//   background-color: var(--color-done-light);
//   color: var(--color-done-heavy);
// }

.external-link {
  display: inline-flex;

  .icon {
    margin: 0 0 0 7px;
    width: 20px;
  }

  .icon-external {
    fill: none;
    stroke: var(--color-link);
    stroke-width: 2px;
  }
}

a {
  color: var(--color-link);
  transition: color 300ms ease;

  &:hover,
  &:focus,
  &:active {
    color: var(--link-shadow);
  }
}

.m-top-0 {
  margin: 0 0 0 0;
}

.m-top-10 {
  margin: 10px 0 0 0;
}

.m-top-20 {
  margin: 20px 0 0 0;
}

.m-top-30 {
  margin: 30px 0 0 0;
}

.m-top-40 {
  margin: 40px 0 0 0;
}

.m-bottom-0 {
  margin: 0 0 0 0;
}

.m-bottom-10 {
  margin: 0 0 10px 0;
}

.m-bottom-20 {
  margin: 0 0 20px 0;
}

.m-bottom-30 {
  margin: 0 0 30px 0;
}

.m-bottom-40 {
  margin: 0 0 40px 0;
}

.relative {
  position: relative;
}

// hr {
//   border: 0;
//   border-top: 1px solid var(--color-bg);
//   margin: 49px 0 50px 0;
// }

hr {
  border: 0;
  background-image: linear-gradient(90deg, #0b5f89, #2cb098);
  height: 2px;
  margin: 39px -16px 19px -16px;

  @include landscape {
    margin: 39px -25px 19px -25px;
  }
}

.capitalized {
  text-transform: capitalize;
}

.truncate {
  @include truncate;
}

dl {
  margin: 0;
}

.invisible {
  opacity: 0;
}
