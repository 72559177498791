$delay: 75;

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    overflow: visible;
  }

  span {
    font-weight: 500;
    color: var(--color-link);
  }

  g {
    path {
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      opacity: 0;
      transform: scale(0);
      position: relative;
      transform-origin: bottom;
      animation-timing-function: ease-in-out;

      &.group-one {
        animation-name: fade-in-out-left;
      }
      &.group-two {
        animation-name: fade-in-out-right;
      }

      @for $i from 0 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * $delay}ms;
        }
      }
    }
  }
}

@keyframes fade-in-out-left {
  0% {
    opacity: 0;
    transform: scale(0) translateY(-200px) translateX(-400px);
  }
  35% {
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
  65% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
  90% {
    opacity: 0;
    transform: scale(1) translateX(80px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translateX(80px);
  }
}

@keyframes fade-in-out-right {
  0% {
    opacity: 0;
    transform: scale(0) translateY(200px) translateX(400px);
  }
  35% {
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
  65% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 0;
    transform: scale(1) translateX(-80px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translateX(-80px);
  }
}
