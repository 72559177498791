@import "../../../ui/media.scss";

.input {
  .input-label {
    font-size: 1.5rem;
    transition: color 300ms ease;
    // padding: 2px 10px;
    font-weight: 500;
    line-height: 1.4;

    padding: 0 0 4px 0;
    min-width: 0;
    flex: 1 0 auto;
    width: 0;

    @include tablet {
      // padding: 2px 10px 6px 10px;
      font-size: 1.7rem;
    }
  }

  .input-label-tag {
    height: 24px;
    line-height: 24px;
    padding: 0 10px 2px 10px;
    border-radius: 5px;
    @include truncate;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
  }

  .input-label-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .required-marker {
    padding: 2px 6px 2px 6px;
    margin: 0 0 0 10px;
    line-height: 1em;
    color: var(--color-red-heavy);
    // border: 1px solid var(--color-red-heavy);
    flex: 0 0 auto;
    font-weight: 500;
    background-color: rgba(246, 234, 236, 1);
    color: var(--color-white);
    background-color: var(--color-tag);
    min-width: 0;
    border-radius: 3px;
  }

  &:focus-within {
    .input-label-tag {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

  .input-frame {
    position: relative;
  }

  select,
  input,
  textarea,
  ::placeholder {
    font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }

  select,
  input,
  textarea {
    appearance: none;
    border: 1px solid var(--color-input-border);
    line-height: var(--height-interaction);
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 0 46px 0 9px;
    color: var(--color-text);
    background-color: var(--color-input-bg);
    font-size: 1.6rem;
    // transition: border-color 300ms ease;

    @include tablet {
      font-size: 1.8rem;
      line-height: var(--height-interaction-tablet);
    }

    &:focus,
    &:active,
    &:hover {
      border: 1px solid var(--color-link);
    }
  }

  textarea {
    line-height: --height-interaction-mini !important;

    @include tablet {
      line-height: var(--height-interaction-mini-tablet);
    }
  }

  select,
  input {
    height: var(--height-interaction);
    @include tablet {
      height: var(--height-interaction-tablet);
    }
  }
  ::placeholder {
    font-style: italic;
    color: var(--color-text-passive);
  }

  .input-messages {
    line-height: 1.2;
    position: relative;
    margin: 4px 0 0 10px;
    font-size: 1.5rem;
    font-style: italic;
    color: var(--color-text-passive);
    min-height: 20px;

    @include tablet {
      font-size: 1.7rem;
      min-height: 22px;
    }
  }

  &.compact {
    .input-messages {
      display: none;
    }
  }

  .input-message,
  .input-hint {
    opacity: 1;
  }

  .input-message {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover,
  &:focus-within {
    .input-messages,
    .input-label {
      color: var(--color-link);
    }

    select,
    input {
      border: 1px solid var(--color-link);
    }
  }

  &.was-successful,
  &.has-error,
  &.is-disabled,
  &.is-pending {
    .input-hint {
      opacity: 0;
    }

    .input-message {
      opacity: 1;
    }
  }

  // &.was-successful {
  //   .input-messages,
  //   .input-label {
  //     color: var(--color-text-success);
  //   }

  //   select,
  //   input {
  //     border: 1px solid var(--color-green);
  //   }
  // }

  &.has-error {
    .input-messages,
    .input-label {
      color: var(--color-text-error);
    }

    select,
    input {
      border: 1px solid var(--color-text-error);
    }
  }

  &.is-pending,
  &.is-disabled {
    cursor: not-allowed;

    .input-messages,
    .input-label {
      color: var(--color-text-passive);
    }

    .checkbox-button,
    .radio-button,
    select,
    input,
    textarea {
      border: 1px solid var(--color-progress-border);
      border: 1px dashed var(--color-slate);
      cursor: not-allowed;
      color: var(--color-text-passive);
    }
  }

  &.is-pending {
    cursor: wait;

    .checkbox-button,
    .radio-button,
    select,
    input {
      cursor: wait;
    }
  }

  // input::-webkit-calendar-picker-indicator {
  //   display: none !important;
  // }
}
