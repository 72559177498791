@import "../../ui/media.scss";

.logo-wrapper {
  background-size: cover;
}

.logo {
  width: 288px;
  display: flex;
  align-items: flex-start;
  position: relative;

  .image {
    background-color: transparent;
  }

  @include tablet {
    width: 400px;
  }

  & > .worldline-logo {
    flex: 0 0 68.5%;

    & > .image {
      top: -1px;
      left: -4px;
    }
  }
}
