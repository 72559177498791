@import "../../../ui/media.scss";

$item-height: 100px;

.indirect-ownership-item {
  height: $item-height;

  .ownership-item-info-name {
    flex: 0 0 calc(100% - 90px);
    max-width: calc(100% - 90px);
  }

  .ownership-item-info-actions {
    flex: 0 0 90px;
  }
}

.indirect-ownership-start,
.indirect-ownership-end,
.indirect-ownership-item {
  position: relative;

  .ownership-item-end-info,
  .ownership-item-info {
    height: 80px;
    margin: -40px 0 0 0;
    left: 0;
    padding: 0 0 0 40px;
    position: absolute;
    top: calc($item-height / 2);
    display: flex;
    align-items: center;
    width: 100%;
  }

  line {
    stroke: var(--color-silver);
    stroke-width: 1px;
    fill: none;
  }

  .outer-circle {
    stroke: none;
    fill: var(--color-white);
  }

  .inner-circle {
    stroke: var(--color-silver);
    stroke-width: 2px;
    fill: var(--color-white);
  }

  .ownership-item-add {
    position: absolute;
    left: 5px;
    bottom: -17px;
    z-index: 10;

    @include tablet {
      left: 3px;
    }
  }
}

.indirect-ownership-start {
  height: calc($item-height / 2);

  .ownership-item-info {
    margin: -40px 0 0 0;
    left: 0;
    padding: 0 0 0 40px;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
