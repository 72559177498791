@import "../../ui/media.scss";

.card {
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  position: relative;
  margin: 0 0 50px 0;
  border: 0;
  background-image: linear-gradient(90deg, #0b5f89 30%, #2cb098);
  border-radius: 10px;
  padding: 4px;

  .card-body {
    background-color: white;
    padding: 16px 12px 20px 12px;
    margin: 0;
    border-radius: 6px;

    @include landscape {
      padding: 20px 21px 30px 21px;
    }
  }

  hr {
    margin: 39px -12px 19px -12px;

    @include landscape {
      margin: 39px -21px 19px -21px;
    }
  }

  .card-action-button {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    background-image: linear-gradient(90deg, #0b5f89 30%, #2c96b0);
  }

  .card-header {
    color: white;

    h4 {
      font-weight: 400;
      align-items: center;
      padding: 4px 12px 6px 12px;
      margin: 0;
      font-size: 2rem;
      max-width: 100%;

      @include landscape {
        padding: 5px 21px 8px 21px;
      }
    }
  }
}
